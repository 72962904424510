<template>
  <div class="home col-12 col-lg-6 c">
    <div class="header">
      <h1>المحادثة بالرسائل</h1>
    </div>
    <!-----start-main---->
    <!---728x90--->
    <div class="login-form">
      <div class="col-12" v-if="noadmins == 0">
        <div class="alert alert-info text-center">
          لا يوجد احد من فريق العمل متاح الآن للمحادثة المباشرة.
          <br />
          <strong>
            قم بإرسال ما تريد وسوف نقوم بإرسال اشعار لك عندما نتواجد في اقرب
            وقت.</strong
          >
        </div>
      </div>
      <div class="col-12 c" style="position: relative">
        <br />
        <div class="col-12 text-center loading" v-if="loading">
          <img
            :src="require('@/assets/loading.svg')"
            style="width: 100px"
            alt=""
          />
        </div>
        <div class="chat-wall g">
          <div
            v-for="msg in messages"
            :class="'col-12 ' + msg.message_from + ' msg'"
            :key="msg._id"
          >
            <span v-if="!msg.file && !msg.sound" v-html="msg.content"></span>
            <span v-if="msg.file">
              <span v-if="msg.file.includes('.pdf')">
                <a :href="msg.file" target="_blank" class="btn btn-info">
                  <i class="fa fa-file"></i>
                  عرض الملف
                </a>
              </span>
              <span v-if="!msg.file.includes('.pdf')">
                <a :href="msg.file" target="_blank">
                  <img :src="msg.file" style="width: 50%" alt="" />
                </a>
              </span>
            </span>
            <span v-if="msg.sound">
              <audio controls>
                <source :src="msg.sound" type="audio/ogg" />
              </audio>
            </span>
            <br />
            <small class="text-muted"
              >{{ msg.date }}
              <span
                class="fa fa-check"
                v-if="!msg.admin_seen && msg.message_from == 'client'"
                >تم الارسال</span
              >
              <span
                class="fa fa-eye"
                v-if="msg.admin_seen && msg.message_from == 'client'"
              >
                تمت رؤيتها &nbsp;</span
              >
            </small>
          </div>
        </div>
        <div class="write-message">
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="اكتب الرسالة..."
              aria-label="Username"
              aria-describedby="basic-addon1"
              style="border-radius: 0px"
              v-model="message"
              v-on:keyup.enter="sendMessageText()"
            />
            <div class="input-group-prepend">
              <span
                class="btn btn-primary"
                @click="sendMessageText()"
                style="border-radius: 0px"
                ><i class="fa fa-paper-plane"></i
              ></span>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="message-confirm-sound"></div>
            <div class="col-12 col-lg-4" style="padding: 1px" v-if="!recording">
              <button
                @click="record()"
                class="btn btn-block btn-success"
                style="border-radius: 0px"
              >
                <i class="fa fa-microphone"></i>
                رسال صوتية
              </button>
            </div>
            <div class="col-12 col-lg-4" style="padding: 1px" v-if="recording">
              <button
                @click="stopRecord()"
                class="btn btn-block btn-danger"
                style="border-radius: 0px"
              >
                {{ timer }}
                <br />
                <i class="fa fa-microphone-slash"></i>
                ايقاف التسجيل
              </button>
            </div>
            <div class="col-12 col-lg-8" style="padding: 1px">
              <button
                @click="attachment()"
                class="btn btn-block btn-default"
                style="border-radius: 0px"
              >
                <i class="fa fa-upload"></i>
                إرسال ملف/صورة
              </button>
            </div>
            <div class="col-12" id="progress-wrp">
              <div class="progress-bar"></div>
              <div class="status">0%</div>
            </div>
          </div>
        </div>
        <br />
        <a href="/" class="text-dark">
          <i class="fa fa-reply"></i>
          العودة للصفحة الرئيسية</a
        >
        <br />
      </div>
    </div>
    <!---728x90--->
    <!--//End-login-form-->
    <!-----start-copyright---->
    <div class="copy-right">
      <!---728x90--->
      <p>جميع الحقوق محفوظة لدى شركة برمجة دوت كوم</p>
    </div>
    <!-----//end-copyright---->
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      api: window.api,
      userdata: window.userdata,
      messages: [],
      loading: false,
      message: null,
      noadmins: 1,
      recording: false,
      timer: "0:0",
      madiaRecorder: null,
      audioChunks: [],
      inta: null,
      lastSound: null,
    };
  },
  methods: {
    record() {
      var g = this;
      g.audioChunks = [];
      g.timer = "0:0";
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        g.madiaRecorder = new MediaRecorder(stream);
        var madiaRecorder = g.madiaRecorder;
        madiaRecorder.start();
        g.recording = true;
        var msec = 0;
        var sec = 0;
        var min = 0;
        g.inta = setInterval(clock, 1000);
        function clock() {
          msec += 1;
          if (msec == 60) {
            sec += 1;
            msec = 0;
            if (sec == 60) {
              sec = 0;
              min += 1;
            }
          }
          g.timer = sec + ":" + msec;
        }
        madiaRecorder.addEventListener("dataavailable", function (event) {
          g.audioChunks.push(event.data);
        });
      });
    },
    stopRecord() {
      var g = this;
      clearInterval(g.inta);
      var madiaRecorder = g.madiaRecorder;
      g.madiaRecorder.stop();
      g.recording = false;
      madiaRecorder.addEventListener("stop", function () {
        var audioBlob = new Blob(g.audioChunks);
        var fileReader = new FileReader();
        fileReader.readAsDataURL(audioBlob);
        fileReader.onloadend = function () {
          var base64String =
            "data:audio/ogg;base64," + fileReader.result.split("base64,")[1];
          g.lastSound = base64String;
          $("#message-confirm-sound").html(`
            <div class='row'>
              <div class='col-12 col-lg-7'>
                <audio controls autoplay>
                <source src="${base64String}" type="audio/ogg">
              Your browser does not support the audio element.
              </audio>
              </div>
              <div class='col-12 col-lg-5'>
                <button class='btn btn-info'>ارسال التسجيل <i class='fa fa-paper-plane'></i></button>
                <br><br>
              </div>
            </div>
          `);
          $("#message-confirm-sound button").click(function () {
            $("#message-confirm-sound").html(" ");
            g.sendSound();
          });
        };
      });
    },
    activeLinks(text) {
      var Rexp =
        /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g; // eslint-disable-line
      return text.replace(
        Rexp,
        "<a href='$1' class='con' target='_blank'>$1</a>"
      ); // eslint-disable-line
    },
    sendSound() {
      var g = this;
      g.loading = true;
      $.post(g.api + "/client/chat/send", {
        data: g.userdata,
        message: "🔊 رسالة صوتية",
        sound: g.lastSound,
      }).then(function () {
        g.getMessages();
        g.message = null;
      });
    },
    attachment() {
      $("body").append(`<input
              type="file"
              id="ingredient_file" style='display:none'
            />`);
      var g = this;

      var Upload = function (file) {
        this.file = file;
      };

      Upload.prototype.getType = function () {
        return this.file.type;
      };
      Upload.prototype.getSize = function () {
        return this.file.size;
      };
      Upload.prototype.getName = function () {
        return this.file.name;
      };
      Upload.prototype.doUpload = function () {
        var that = this;
        var formData = new FormData();

        // add assoc key values, this will be posts values
        formData.append("file", this.file, this.getName());
        formData.append("phone", g.userdata.phone);
        $.ajax({
          type: "POST",
          url: g.api + "/client/chat/upload",
          xhr: function () {
            var myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener(
                "progress",
                that.progressHandling,
                false
              );
            }
            return myXhr;
          },
          success: function (data) {
            $("#progress-wrp").fadeOut("slow");
            $("#ingredient_file").remove();
            g.loading = true;
            $.post(g.api + "/client/chat/send", {
              data: g.userdata,
              message: "📷 صورة/ملف",
              file: data.response,
            }).then(function () {
              g.getMessages();
              g.message = null;
            });
          },
          error: function (error) {
            // handle error
            $("#progress-wrp").fadeOut("slow");
            $("#ingredient_file").remove();
            alert("حدث خطأ، ربما صيغة الملف غير مدعومة");
          },
          async: true,
          data: formData,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000,
        });
      };

      Upload.prototype.progressHandling = function (event) {
        var percent = 0;
        var position = event.loaded || event.position;
        var total = event.total;
        var progress_bar_id = "#progress-wrp";
        if (event.lengthComputable) {
          percent = Math.ceil((position / total) * 100);
        }
        // update progressbars classes so it fits your code
        $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
        $(progress_bar_id + " .status").text(percent + "%");
      };
      $("#ingredient_file").click();
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    },
    sendMessageText() {
      var g = this;
      if (g.message) {
        if (g.message.trim() != "") {
          g.loading = true;
          $.post(this.api + "/client/chat/send", {
            data: this.userdata,
            message: this.activeLinks(this.message),
          }).then(function () {
            g.getMessages();
            g.message = null;
          });
        }
      }
    },
    getMessages() {
      var g = this;
      this.loading = true;
      $.post(this.api + "/client/chat/messages", {
        data: this.userdata,
      }).then(function (r) {
        g.loading = false;
        g.messages = r.response;
        setTimeout(() => {
          var scroll = $(".chat-wall");
          scroll.animate({ scrollTop: scroll.prop("scrollHeight") });
        }, 10);
      });
    },
  },
  created() {
    var g = this;
    g.sockets.subscribe("new-message", function (data) {
      var audio = new Audio(require("@/assets/new-message.mp3"));
      audio.play();
      g.getMessages();
    });
    g.sockets.subscribe("seen", function (data) {
      g.getMessages();
    });
    g.getMessages();
    $.post(this.api + "/client/chat/available", {
      data: this.userdata,
    }).then(function (r) {
      g.noadmins = r.response;
    });
  },
};
</script>
<style scoped>
.chat-wall {
  width: 100% !important;
  margin: 0px auto;
  border: 2px solid #ddd;
  border-radius: 5px 5px 0px 0px;
  overflow-y: scroll;
  height: 400px;
}
.write-message,
.write-message .row {
  margin: 0px auto;
  width: 100% !important;
}
.admin {
  background: #adddd0;
  text-align: right !important;
}
.client {
  background: #aebdca;
  text-align: left !important;
}
.msg {
  padding: 5px;
  border-radius: 5px 0px;
  margin-top: 5px;
}
.loading {
  position: absolute;
  right: 0px;
  top: 0px;
}
</style>
<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>
